
// 检查手机号码格式
export function validateMobile(str) {
  return /^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16[6-7])|(17[1-8])|(18[0-9])|(19[1|3])|(19[5|6])|(19[8|9]))\d{8}$/.test(str);
}

// 检查邮箱格式
export function validateEmail(str) {
  return /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/.test(str);
}

// 检查电话格式
export function validatePhone(str) {
  return /^(0\d{2,4}-)?\d{8}$/.test(str);
}

// 检查QQ格式
export function validateQQ(str) {
  return /^[1-9][0-9]{4,}$/.test(str);
}

// 检查验证码格式
export function validateSmsCode(str) {
  return /^\d4$/.test(str);
}
// 校验 URL
export function validURL(url) {
  const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return reg.test(url)
}

// 校验特殊字符
export function specialCharacter(str) {
  const reg = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\]<>《》/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？ ]")
  return reg.test(str)
}

// 校验是否包含中文字符
export function validHasChinese(str) {
  return /.*[\u4e00-\u9fa5]+.*$/.test(str);
}

// 校验是否全是中文字符
export function validAllChinese(str) {
  return /^[\u4E00-\u9FA5]+$/.test(str);
}

// 校验是否全是字母或数字
export function validAllNumberOrletter(str) {
  return /^[0-9a-zA-Z]*$/.test(str);
}

// const checkPassword = (rule, value, callback) => {
//     if (value === '') {
//         callback(new Error('请输入密码'));
//     } else {
//         var ls = 0;
//         if (this.accountForm.Password !== '') {
//             if (this.accountForm.Password.match(/([a-z])+/)) {
//                 ls++;
//             }
//             if (this.accountForm.Password.match(/([0-9])+/)) {
//                 ls++;
//             }
//             if (this.accountForm.Password.match(/([A-Z])+/)) {
//                 ls++;
//             }
//             if (this.accountForm.Password.match(/([\W])+/) && !this.accountForm.Password.match(/(![\u4E00-\u9FA5])+/)) {
//                 ls++;
//             }
//             if (this.accountForm.Password.length < 7 || this.accountForm.Password.length > 18) {
//                 callback(new Error('要求7-18位字符'));
//                 ls = 0;
//             }
//             if (this.accountForm.Password.match(/([\u4E00-\u9FA5])+/)) {
//                 callback(new Error('不能包含中文字符'));
//                 ls = 0;
//             }
//             switch (ls) {
//                 case 0: this.passwordPercent = 0; callback(new Error('数字、小写字母、大写字母以及特殊字符')); break;
//                 case 1: this.passwordPercent = 30; callback(new Error('数字、小写字母、大写字母以及特殊字符')); break;
//                 case 2: this.passwordPercent = 60; callback(new Error('数字、小写字母   、大写字母以及特殊字符')); break;
//                 case 3:
//                 case 4: this.passwordPercent = 100; break;
//                 default: this.passwordPercent = 0; break;
//             }
//         }
//         callback();
//     }
// }